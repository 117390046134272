
table {
    
    min-width: max-content;
    
    border-collapse: separate;
    border-spacing: 0px;    
    
}
.table-filter {
    border-radius: 5px;
}

table th{
    position: sticky; 
    top: 0px;
    width: 500px;
    
    background-color: #133b5c;
    color: rgb(241, 245, 179);
    
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    outline: 0.7px solid black;
    border: 1.5px solid black;

} 

table th, table td {
    
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

table td {
    text-align: left;
    
    font-size: 15px;
    border: 1px solid rgb(177, 177, 177);
    padding-left: 20px;
    
}